import { Box, Button, Checkbox, Chip, Grid, IconButton, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddIcon from '@mui/icons-material/Add';

import { getContactLabels, getSentiment, getSummary, tagConversation,updateLabelsOfContact as updateLabelsOfContactInServer  } from '../../services/conversations';
import { formatKey } from '../../utils/stringFunctions';
import { useAppStore } from './AppStore';
import { HighlightOff } from '@mui/icons-material';
import { hasArrayChanged } from '../../utils/arrayFunctions';
import CreateContactInitializer from '../Common/CreateContactInitializer';

function ConversationInfo({ handleInfoToggle, room }) {
  const [roomId,setRoomId] = useState(room?.documentId)
  const {contactLabels, getContactInfo, useLabelsByContactId, useUpdatedLabelsByRoomId, updateLabelsInContext,syncLabelsOfContact , fetchContactLabels} = useAppStore();

  const [conversationSummary, setConversationSummary] = useState(room?.variables?.conversationSummary ?? "");
  const [customerSentiment, setCustomerSentiment] = useState(room?.variables?.customerSentiment ?? "");
  const [conversationTags, setConversationTags] = useState(room?.variables?.conversationTags ?? []);

  const [customer,setCustomer] = useState({})
  const [contactId, setContactId] = useState(null);

  const actualLabelOfContact = useLabelsByContactId(contactId);
  const updatedLabelOfContact = useUpdatedLabelsByRoomId(roomId) 
  const [showSave,setShowSave]=useState(false)

  const [createDialogOpen,setCreateDialogOpen]=useState(false)
  // const handleGetSummary = async () => {
  //   try {
  //     const response = await getSummary(instanceId, { conversationId: room.conversationId, channel: room.channel });
  //     if (response.status === 200) {
  //       setConversationSummary(response.data.summary ?? "");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const handleGetSentiment = async () => {
  //   try {
  //     const response = await getSentiment(instanceId, { conversationId: room.conversationId, channel: room.channel });
  //     if (response.status === 200) {
  //       setCustomerSentiment(response.data.sentiment ?? "");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const handleGetTags = async () => {
  //   try {
  //     const response = await tagConversation(instanceId, { conversationId: room.conversationId, channel: room.channel });
  //     if (response.status === 200) {
  //       setConversationTags(response.data.tags ?? []);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };


  const handleDeleteLabel = async (labelId) => {
    try{
        const updatedLabels = updatedLabelOfContact.filter(label => label !== labelId);
        await updateLabelsInContext(roomId, updatedLabels);
        await syncLabelsOfContact(roomId,contactId)

        // setTimeout(async () => {
        //   await getContactInfo(roomId, contactId);
        // }, 3000); // 10000 milliseconds = 10 seconds
    } 
    catch (e) {
        console.error('Error during label deletion:', e);
    }
};


  const handleCreateLabel = async () => {
    try {
      if (!!labelError || newLabelName.trim() === '') return;
      const response = await updateLabelsOfContactInServer({contactId,labelName:newLabelName,action: 'create'});
      if(response?.status===200){
        setNewLabelName("")
      }
      setTimeout(async () => {
        await fetchContactLabels()
        await getContactInfo(roomId, contactId);
      }, 3000);
    } catch (e) {
      console.error(e);
    }
  };

  const [newLabelName,setNewLabelName]=useState("")
  const [labelError, setLabelError] = useState("");
  const handleLabelNameChange = (updatedLabelName) => {
    const labelExists = contactLabels.some(label => label?.name?.toLowerCase() === updatedLabelName.toLowerCase());
    if (!labelExists) {
      setLabelError("");
    } else {
      setLabelError('Label already exists. Please choose a different name.');
    }
    setNewLabelName(updatedLabelName);
  };

  useEffect(() => {
    setRoomId(room?.documentId)
    const newCustomer = room.variables?.customer || {};
    const newContactId = newCustomer.contactId;
    setCustomer(newCustomer);
    setContactId(newContactId);
    if (newContactId) {
      getContactInfo(roomId,newContactId);
    }
  }, [room]);

  const [open, setOpen] = useState(false);
  useEffect(()=>{
    setShowSave(
      hasArrayChanged(actualLabelOfContact, updatedLabelOfContact)
    );
  },[actualLabelOfContact,updatedLabelOfContact])

  return (
    <React.Fragment>
        <Grid container direction="row" sx={{ height: '100%', borderLeft: '1px solid #F2F2F2' }}>
          <Grid item container direction="column" sx={{ maxHeight: '100%', overflowY: 'auto' }}>
            <Box sx={{ position: 'sticky', top: 0, minHeight: `10vh`, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pl: 2, pr: 2, borderBottom: '1px solid #F2F2F2' }}>
              <IconButton sx={{ ml: 'auto' }} onClick={handleInfoToggle}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', zIndex: 1 }}>
              <Box
                sx={{
                  overflowY: 'auto',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  scrollbarWidth: "none", // Firefox
                  "msOverflowStyle": "none", // IE and Edge
                  "&::-webkit-scrollbar": {
                    width: "0.4em", // Adjust as needed
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent", // Make the track invisible
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "transparent", // Make the thumb (scrollbar) invisible
                  },
                  p: 2
                }}
              >
                {customer && <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: 16, mb: 2 }}>Customer Information</Typography>}
                <Grid container direction="row" spacing={1}>
                  {customer && Object.keys(customer).map((key) => (
                    key !== 'contactId' && (
                      <Grid item container xs={12} key={key}>
                        <Grid item xs={6} sx={{ display: 'inline-flex' }}>
                          <Typography sx={{ fontFamily: 'DM Sans Medium', color: '#68677F' }}>
                            {formatKey(key)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', wordWrap: 'break-word' }}>
                          <Typography sx={{ fontFamily: 'DM Sans Light', color: '#68677F' }}>
                            {customer[key]}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  ))}
                </Grid>
                {/* {ticket &&  <Typography sx={{fontFamily:'DM Sans Medium',fontSize:16,mb:2}}>Ticket Information</Typography>}
                <Grid container direction="row" spacing={1}>
                  {ticket && Object.keys(ticket).map((key) => (
                      <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{display:'inline-flex'}}>
                            <Typography sx={{fontFamily:'DM Sans Medium',color:'#68677F'}}>
                              {formatKey(key)}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{display:'flex',flexDirection:'column',wordWrap:'break-word'}}>
                            <Typography sx={{fontFamily:'DM Sans Light',color:'#68677F',}}>
                              {ticket[key]}
                            </Typography>
                        </Grid>
                      </Grid>
                  ))}
                </Grid> */}
                <Typography sx={{fontSize:'13px'}}>{conversationSummary}</Typography>
                <Typography>{customerSentiment}</Typography>
                <Typography>{conversationTags}</Typography>
                  
                {contactLabels && customer && contactId && (
                  <Grid container direction="column" spacing={1} sx={{mt:2}}>
                    <Grid item xs={12} sx={{ display: 'inline-flex' }}>
                      <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: 16, mb: 2 }}>Labels</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container spacing={1}> 
                      {actualLabelOfContact.length > 0 ? (
                        actualLabelOfContact.map((labelId, index) => {
                          const label = contactLabels.find((contactLabel) => contactLabel.labelId === labelId);
                          return (
                            label && (
                              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <Tooltip title={label.name} arrow placement='top'>
                                    <Chip
                                      size='small'
                                      label={label.name}
                                      key={index}
                                      sx={{
                                        minWidth: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                      deleteIcon={<HighlightOff/>}
                                      onDelete={() => handleDeleteLabel(labelId)}
                                      variant="contained"
                                    />
                                  </Tooltip>
                              </Grid>
                            )
                          );
                        })
                      ) : (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography sx={{ fontFamily: 'DM Sans Light', color: '#68677F' }}>No labels found</Typography>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2, gap: 1 }} container>
                        {contactLabels.length > 0 ? (
                          <React.Fragment>
                          <Select
                            color='secondary'
                            size='small'
                            multiple
                            value={updatedLabelOfContact}
                            onChange={(e) =>{updateLabelsInContext(roomId, e.target.value)}}
                            displayEmpty
                            fullWidth
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            renderValue={(selected) =>
                              selected.length === 0 ? (
                                <em>Select Labels</em>
                              ) : (
                                selected
                                  .map((labelId) => {
                                    const label = contactLabels.find((l) => l.labelId === labelId);
                                    return label ? `${label.name}` : null;
                                  })
                                  .filter(name => name !== null)
                                  .join(', ')
                              )
                            }
                          >
                            {contactLabels.map((label) => (
                              <MenuItem color='secondary' key={label.labelId} value={label.labelId} sx={{p:0}}>
                                <Checkbox size='small' color='secondary' checked={updatedLabelOfContact.includes(label.labelId)} />
                                <ListItemText primary={label.name} />
                              </MenuItem>
                            ))}
                            <MenuItem disableRipple sx={{ justifyContent: 'center'}}>
                              <Button
                                fullWidth
                                size='small'
                                color='secondary'
                                variant='contained'
                                onClick={(e) => {
                                  syncLabelsOfContact(roomId, contactId);
                                  setOpen(false);
                                }}
                                disabled={!showSave}
                                disableRipple
                                sx={{ margin: '8px 0' }}
                              >
                                Save
                              </Button>
                            </MenuItem>
                          </Select>
                        </React.Fragment>
                        ) : (
                          <Typography sx={{ color: '#68677F' }}>
                            Please create a label on the platform first to set it on contacts.
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                )}
                {!contactId && 
                  <Box sx={{pt:2}}>
                    <Button
                      size='small'
                      color='secondary'
                      variant='contained'
                      startIcon={<AddIcon />}
                      onClick={() => setCreateDialogOpen(true)}
                    >
                      Create Contact
                    </Button>
                  </Box>
                }
                {contactId && 
                    <Grid container direction="column" spacing={1} sx={{mt:2}}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'inline-flex' }}>
                        <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: 16}}>Create New Label</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ gap: 1 }} container>
                          <TextField
                            color='secondary'
                            size='small'
                            autoFocus
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newLabelName}
                            onChange={(e) => handleLabelNameChange(e.target.value)}
                            error={!!labelError}
                            helperText={labelError}
                          />
                          <Button
                            size='small'
                            color='secondary'
                            variant="contained"
                            onClick={handleCreateLabel}
                            sx={{ width: '125px' }}
                            disabled={!!labelError || newLabelName.trim() === ''}
                          >
                            Create
                          </Button>
                      </Grid>
                    </Grid>
                }

                {
                /* 
                <Box sx={{ position: 'sticky', mt: 'auto', minHeight: `10vh`,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width: '100%'}}>
                    <Button onClick={()=>{handleGetSummary()}} disabled>
                      Get Summary
                    </Button>
                    <Button onClick={()=>{handleGetSentiment()}} disabled>
                      Get Sentiment
                    </Button>
                    <Button onClick={()=>{handleGetTags()}} disabled>
                      Get Tags
                    </Button>
                </Box> 
                */
                }

              </Box>
            </Box>
          </Grid>
        </Grid>
        <CreateContactInitializer dialogOpen={createDialogOpen} handleDialogClose={()=>{setCreateDialogOpen(false)}} prefilData={room}/>
    </React.Fragment>
   
  );
}

export default ConversationInfo;
