import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Box, TextField, Grid, IconButton, Typography, Stack, Tooltip } from '@mui/material';
import { SendRounded, SendTimeExtensionRounded } from '@mui/icons-material';
import { useAppStore } from './AppStore';
import TemplatedMessageInitializer from '../Common/TemplatedMessageInitializer';
import MediaMessageInitializer from '../Common/MediaMessageInitializer';
import AccentsEmojiSelector from '../Common/AccentsEmojiSelector';

function ConversationFooter({ room }) {
    const roomId = room?.documentId;
    const { useMessageByRoomId, changeMessageInContext, sendMessage, deleteMessageFromContext } = useAppStore();
    const globalMessage = useMessageByRoomId(roomId);

    // Local state for input value
    const [localMessage, setLocalMessage] = useState(globalMessage?.text || "");
    const inputRef = useRef(null);

    // Sync local state with global state when the global message changes
    useEffect(() => {
        setLocalMessage(globalMessage?.text || "");
    }, [globalMessage?.text]);

    const handleCustomerMessageKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && localMessage.trim() !== "") {
            handleSendMessage(e);
        } else if (e.key === 'Enter' && e.shiftKey) {
            setLocalMessage((prev) => prev + "\n");
            changeMessageInContext(room.documentId, globalMessage?.text.concat('\n') ?? "");
        }
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        const sent = sendMessage(room.documentId, globalMessage);
        if (sent) {
            setLocalMessage(""); 
            deleteMessageFromContext(room.documentId);
        }
    };

    const handleInputChange = (value) => {
        setLocalMessage(value);
        changeMessageInContext(room.documentId, value);
    };
    const handleInsertAtCursor = (text) => {
        const input = inputRef.current;
        if (input) {
            const start = input.selectionStart;
            const end = input.selectionEnd;
            const updatedText =
                localMessage.substring(0, start) +
                text +
                localMessage.substring(end);
            setLocalMessage(updatedText);

            // Move the cursor to the end of the inserted text
            setTimeout(() => {
                input.setSelectionRange(start + text.length, start + text.length);
                input.focus();
            }, 0);
        }
    };
    
    const has24HrsPassed = useMemo(() => {
        const lastActivityDate = new Date(room.lastActivity?.timestamp);
        const currentDate = new Date();
        const diffInHours = (currentDate - lastActivityDate) / (60 * 60 * 1000);
        return diffInHours > 24;
    }, [room.lastActivity]);

    const isWhatsApp = room.channel === 'whatsapp';
    const prefilData = { customer: room?.variables?.customer, channel: room.channel, phoneNumber: room.conversationId, type: "template" };

    return (
        <React.Fragment>
            {has24HrsPassed && isWhatsApp ? (
                <Grid container direction="row" spacing={2} sx={{ pl: 2, pr: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderRadius: '5px', p: 2, background: '#FFE6CB' }}>
                            <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: '14px', mr: 1 }}>
                                Messaging window is closed. Initiate a new WhatsApp room by triggering a templated message.
                            </Typography>
                            <TemplatedMessageInitializer prefilData={prefilData} buttonIcon={<SendTimeExtensionRounded />} />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <form
                    onSubmit={handleSendMessage}
                    style={{ width: '100%' }}
                >
                    <Stack direction="row" sx={{ pl: 1, pr: 1, justifyContent: 'center' }} spacing={1}>
                        <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-start' }}>
                            <TemplatedMessageInitializer prefilData={prefilData} buttonIcon={<SendTimeExtensionRounded />} resetToDefault={false} />
                            {isWhatsApp &&
                                <MediaMessageInitializer channel={room.channel} roomId={room.documentId} conversationId={room.conversationId} />
                            }
                            <AccentsEmojiSelector onInsertAtCursor={handleInsertAtCursor}/>
                        </Stack>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextField
                                inputRef={inputRef} // Attach ref
                                color='secondary'
                                variant='outlined'
                                margin='none'
                                fullWidth
                                sx={{ minHeight: '40px', overflowY: 'hidden' }}
                                value={localMessage}
                                onKeyDown={handleCustomerMessageKeyDown}
                                onChange={(e)=>{handleInputChange(e.target.value)}}
                                multiline
                                maxRows={8}
                                size='small'
                                type="text"
                                placeholder='Your Message'
                            />
                        </Box>
                        <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-start' }}>
                            <Tooltip arrow placement="top" title="Send Message" enterDelay={1000}>
                                <IconButton
                                    type="submit"
                                    color='secondary'
                                    disableRipple
                                    sx={{
                                        minHeight: '40px',
                                        background: "#F5F5F5",
                                        borderRadius: "5px",
                                        pointerEvents: !localMessage.trim() ? 'none' : 'auto',
                                    }}
                                >
                                    <SendRounded />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </form>
            )}
        </React.Fragment>
    );
}

export default ConversationFooter;
