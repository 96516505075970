import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {Badge, Box,Typography} from '@mui/material';
import TemplatedMessageInitializer from './TemplatedMessageInitializer';
import {useAppStore } from '../Main/AppStore';
import PipedriveResize from './PipedriveResize';
import SocketReconnect from './SocketReconnect';


function NotificationBar() {
  const [searchParams] = useSearchParams();
  const platform = searchParams.get('platform');
  const sdkId = searchParams.get('id');
  const {notificationsCount} = useAppStore();
  
  return (
   <React.Fragment>
     <Box
      sx={{
        display:'inline-flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'left',
        alignItems:'center',
        width:'100%'
      }}
    >
        <Typography sx={{fontFamily:'DM Sans Light',fontSize:'18px',fontWeight:600}}>Conversations</Typography>
        <Box sx={{ml:'auto',display:'flex',flexDirection:'row',alignItems:'center',gap:3}}>
            {platform==='pipedrive' && sdkId && 
              <PipedriveResize sdkId={sdkId}/>
            }
            <SocketReconnect/>
            <TemplatedMessageInitializer/>
            {notificationsCount>0 &&
              <Badge color="secondary" badgeContent={notificationsCount} max={99} sx={{mr:1}} />
            }
        </Box>
      </Box>
   </React.Fragment>
  )
}

export default NotificationBar