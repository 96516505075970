import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip, Snackbar, Alert, Box, CircularProgress } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import socket from "../../socket/index.js";

function SocketReconnect() {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(!socket.connected);
    const [reconnectMessage, setReconnectMessage] = useState("Click to Reconnect");

    useEffect(() => {
        const onConnect = () => {
            setIsConnected(true);
            setIsReconnecting(false);
            setShowSnackbar(false);
            setReconnectMessage("Connected Successfully!");
        };

        const onDisconnect = () => {
            setIsConnected(false);
            setShowSnackbar(true);
            setReconnectMessage("Click to Reconnect");
        };

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
        };
    }, []);

    const handleSocketReconnect = () => {
        if (!isConnected) {
            setIsReconnecting(true);
            setReconnectMessage("Trying to connect...");

            socket.connect();

            setTimeout(() => {
                if (!socket.connected) {
                    setIsReconnecting(false);
                    setReconnectMessage("Click to Reconnect");
                }
            }, 10000); // 10 seconds delay
        }
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ p: 2 }}
        >
            <Snackbar
                open={showSnackbar}
                autoHideDuration={null}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity="error" 
                    sx={{ width: '100%' }}
                >
                    Connection lost. Please reconnect to use the app.
                </Alert>
            </Snackbar>

            {!isConnected && (
                <Tooltip arrow placement="top" title={reconnectMessage} enterDelay={1000}>
                    <IconButton
                        color={isReconnecting ? "primary" : "secondary"}
                        size="small"
                        sx={{ borderRadius: "5px" }}
                        disableRipple
                        onClick={handleSocketReconnect}
                        disabled={isReconnecting}
                    >
                        {isReconnecting ? <CircularProgress size={20} /> : <AutorenewIcon />}
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
}

export default SocketReconnect;
