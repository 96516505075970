import React, { useEffect, useState } from 'react'
import { Dialog,DialogContent,DialogActions,Typography,Slide, IconButton, Tooltip, Stack,CircularProgress, FormControl, TextField, FormLabel, MenuItem,Select, InputAdornment, Button, RadioGroup, FormControlLabel, Radio, Grid, Box, Card, CardContent, CardActions, Icon} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { phoneCountryCodes } from '../../utils/phoneFunctions.js';
import phone from "phone";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { fetchMessageTemplates } from '../../services/conversations.js';
import { formatKey } from '../../utils/stringFunctions.js';
import { useAppStore } from '../Main/AppStore.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TemplatedMessageInitializer({buttonIcon,prefilData,resetToDefault}) {
    const authUser = useAuthUser();
    const [instanceId, setInstanceId] = useState(authUser?.instanceId ?? "");

    const {sendMessage,useChannels}=useAppStore()
    const channels=useChannels(instanceId)
    const availableChannels = Object.keys(channels).filter((key) => channels[key]);

    const [messageTemplates,setMessageTemplates]=useState({})

    const [selectedTemplate,setSelectedTemplate]=useState(null)
    const [variableValues,setVariableValues]=useState({})
    const [dialogOpen,setDialogOpen]=useState(false)
    const handleDialogOpen=()=>{setDialogOpen(true)}
    const handleDialogClose=()=>{setDialogOpen(false)}
    

    const [phoneNumber,setPhoneNumber]=useState({countryCode:"",number:""})
    const handlePhoneNumberFieldChange = (field, newValue) => {
        setPhoneNumber((prev) => {
            const updated = { ...prev };
            updated[field] = newValue;
            return updated;
        });
    };
    const [channel,setChannel]=useState(null)
    const [type,setType]=useState(null)
    const [text,setText]=useState("")

    const handleChannelChange=(newValue)=>{
        setChannel(newValue);
        setSelectedTemplate(null)
        setVariableValues({})
        if(newValue==="whatsapp") setType("template")
    }

    const handleSend=()=>{
        const recipient=phone(phoneNumber.number,{country:phoneNumber.countryCode})
        //console.log(recipient,channel,type)
        if(!recipient.isValid) return
        
        const conversationId=recipient.phoneNumber
        const roomId=`conversations:${instanceId}:${channel}:${conversationId}`
        if(channel==="sms" && type==="text"){
            sendMessage(roomId,{type:type,[type]:text},{channel:channel,roomId:roomId,conversationId:conversationId,customer:{userId:recipient.phoneNumber}})
        }
        if(channel==="sms" && type==="template"){
            sendMessage(roomId,{type:type,template:selectedTemplate,params:[variableValues]},{channel:channel,roomId:roomId,conversationId:conversationId,customer:{userId:recipient.phoneNumber}})
        }
        if(channel==="whatsapp" && type==="template"){
            sendMessage(roomId,{type:'template',template:selectedTemplate,params:[variableValues]},{channel:channel,roomId:roomId,conversationId:conversationId,customer:{userId:recipient.phoneNumber}})
        }
        setDialogOpen(false)
        if(resetToDefault){
            setPhoneNumber({countryCode:"",number:""})
            setChannel(null)
            setType(null)
            setText("")
        }
        setSelectedTemplate(null)
        setText("")        
    }
    const fetchTemplates=async()=>{
        const response=await fetchMessageTemplates()
        if(response.status===200){
            if(response.data){
                setMessageTemplates(response.data)
            }
        }
    }

   
    const handleVariableValueChange=(variable,value)=>{
        setVariableValues((prev)=>{
            const updated={...prev}
            updated[variable]=value
            return updated
        })
    }

    const collectVariables = (components) => {
        const variablesContent = [];
        components.forEach((component) => {
            if (component.type === 'HEADER' && component?.formatType!=='STATIC') {
                Object.values(component?.mappings ?? {}).forEach((value, index) => {
                    variablesContent.push(value);
                });
            }
            else if (component.type === 'BODY'){
                Object.values(component?.mappings ?? {}).forEach((value, index) => {
                    variablesContent.push(value);
                });
            }
            else if (component.type === 'BUTTONS') {
                component.buttons.forEach((button) => {
                    if (button.mappings && Object.keys(button.mappings).length > 0) {
                        Object.entries(button.mappings).forEach(([key, value]) => {
                            variablesContent.push(value);
                        });
                    }
                });
            } 
        });
        return variablesContent;
    };


    useEffect(()=>{
        fetchTemplates()
    },[])

    useEffect(()=>{
        if(prefilData){
            if(prefilData?.channel!==null){
                setChannel(prefilData?.channel)
            }
            if(prefilData?.phoneNumber!=null){
                const recipient=phone(prefilData?.phoneNumber)
                recipient.isValid && setPhoneNumber({countryCode:recipient.countryIso2,number:recipient.phoneNumber.replace(recipient.countryCode,'')})
            }
            if(prefilData?.customer){
                setVariableValues(prefilData.customer)
            }
            if(prefilData?.type){
                setType(prefilData?.type)
            }
        }
    },[prefilData])

    useEffect(() => {
        if (availableChannels.length === 1) {
            setChannel(availableChannels[0]); 
        }
    }, [availableChannels]);

    const isButtonDisabled = (type === "template" && selectedTemplate === null) || (type === "text" && text.trim() === "");
    
    return (
        <React.Fragment>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '80vh',
                        p: 0,
                        overflow: "auto",
                        scrollbarWidth: "0.2em", // Firefox
                        msOverflowStyle: "none", // IE and Edge
                        "&::-webkit-scrollbar": {
                            width: "0.2em", // Adjust as needed
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "transparent", // Make the track invisible
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#DF6437", // Make the thumb (scrollbar) invisible
                            borderRadius: "50px",
                        },
                    }}
                >
                    <Grid container spacing={2} sx={{ position: 'sticky', top: 0, background: '#FFFFFF',p:2}}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <FormControl fullWidth>
                                <Typography sx={{ fontFamily: 'DM Sans Medium' }}>Country</Typography>
                                <Select
                                    sx={{ background: '#FFFFFF' }}
                                    size='small'
                                    value={phoneNumber.countryCode}
                                    onChange={(e) => { handlePhoneNumberFieldChange('countryCode', e.target.value) }}
                                >
                                    {phoneCountryCodes.map((country, i) => (
                                        <MenuItem
                                            key={i}
                                            value={country.code}
                                            sx={{
                                                fontFamily: "DM Sans Regular",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {country.name}&nbsp;&nbsp;({country.dialCode})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <FormControl fullWidth>
                                <Typography sx={{ fontFamily: 'DM Sans Medium' }}>Phone number</Typography>
                                <TextField
                                    error={!phone(phoneNumber.number, { country: phoneNumber.countryCode }).isValid}
                                    size="small"
                                    sx={{ background: '#FFFFFF' }}
                                    value={phoneNumber.number}
                                    onChange={(e) => { handlePhoneNumberFieldChange('number', e.target.value.replace(/\D/g, '')) }}
                                    inputProps={{
                                        maxLength: 20
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                {phoneNumber.number.length}/20
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
       
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <FormControl fullWidth>
                                {availableChannels.length >= 1 ? (
                                    <FormControl fullWidth>
                                        <Typography sx={{ fontFamily: 'DM Sans Medium' }}>
                                        Select Communication Channel
                                        </Typography>
                                        <Select
                                        size="small"
                                        sx={{
                                            fontFamily: "DM Sans Regular",
                                            fontSize: "14px",
                                        }}
                                        value={channel}
                                        onChange={(e) => handleChannelChange(e.target.value)}
                                        >
                                        {availableChannels.map((availableChannel) => (
                                            <MenuItem key={availableChannel} value={availableChannel}>
                                                {availableChannel==='sms'?"SMS":"Whatsapp"}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    ) : (
                                    <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: '14px' }}>
                                        Onboard SMS or Whatsapp to Start Using Conversations
                                    </Typography>
                                    )
                                }
                            </FormControl>
                        </Grid>
                        {channel === "sms" && (
                            <Grid item xs={9} sm={9} md={8} lg={8} xl={8}>
                                <FormControl fullWidth>
                                    <Typography sx={{ fontFamily: 'DM Sans Medium' }}>Select SMS Type</Typography>
                                    <RadioGroup
                                        defaultValue="text"
                                        value={type}
                                        onChange={(e) => { setType(e.target.value);setSelectedTemplate(null);setText("")}}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <FormControlLabel value="text" control={<Radio />} label="Freeform Message" />
                                        <FormControlLabel value="template" control={<Radio />} label="Templated Message" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}
                        {channel !== null &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography sx={{ fontFamily: 'DM Sans Medium' }}>
                                    {channel === "sms" ? "SMS" : channel === "whatsapp" ? "Whatsapp" : null}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    {channel !== null &&
                        <Grid container spacing={2} sx={{p:2}}>
                            {channel === "sms" &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {type === "text" &&
                                        <React.Fragment>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline
                                                    rows={5}
                                                    value={text}
                                                    onChange={(e) => {
                                                        setText(e.target.value);
                                                    }}
                                                    placeholder="Message"
                                                    sx={{ background: "#FFFFFF" }}
                                                    required
                                                />
                                            </FormControl>
                                            <Box
                                                sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}
                                            >
                                                <Typography
                                                    sx={{
                                                        mt:1,
                                                        alignSelf: "flex-end",
                                                        fontFamily: "DM Sans Medium",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    {Math.ceil(text.length / 160)} Segments
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    }
                                    {type === "template" && selectedTemplate === null &&
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container spacing={2} direction="row">
                                            {messageTemplates.smsTemplates?.map((template, index) => (
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                                                    <Card onClick={() => { setSelectedTemplate(template) }}>
                                                        <CardContent
                                                            sx={{
                                                                height: '100px',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "DM Sans",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                {template?.name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    mt: 1,
                                                                    fontFamily: "DM Sans Light",
                                                                    fontSize: '12px',
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    overflow: "hidden",
                                                                    WebkitLineClamp: 3,
                                                                    lineClamp: 3,
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {template?.text}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    }
                                    {type === "template" && selectedTemplate !== null &&
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        mt: 1,
                                                        fontFamily: "DM Sans Light",
                                                        fontSize: '14px',
                                                        display: "-webkit-box",
                                                        WebkitBoxOrient: "vertical",
                                                        overflow: "hidden",
                                                        WebkitLineClamp: 3,
                                                        lineClamp: 3,
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {selectedTemplate?.text}
                                                </Typography>
                                            </Box>
                                            {[...new Set(Object.values(selectedTemplate.mappings))]?.map((value, index) => (
                                                <Grid container direction="row" spacing={1} sx={{mt:4}} key={index}>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                                        <Typography sx={{ fontFamily: "DM Sans", fontSize: "14px" }}>
                                                            {formatKey(value)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={10} lg={10} xl={10}>
                                                        <TextField 
                                                            fullWidth
                                                            size='small'
                                                            value={variableValues[value] ?? ""}
                                                            onChange={(e) => { handleVariableValueChange(value, e.target.value) }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Box sx={{mt:5}}>
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    sx={{
                                                        background: "#F5F5F5",
                                                        borderRadius: "5px",
                                                    }} 
                                                    disableRipple 
                                                    onClick={()=>{
                                                        setSelectedTemplate(null)
                                                    }}

                                                >
                                                    <ArrowBackRoundedIcon/>
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {channel === "whatsapp" &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {selectedTemplate===null &&
                                        <Grid container spacing={2}>
                                            {messageTemplates.whatsappTemplates?.map((template, index) => (
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                                                    <Card onClick={() => { setSelectedTemplate(template) }} >
                                                        <CardContent
                                                            sx={{
                                                                minHeight: '100px',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "DM Sans",
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                {template?.name}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    mt: 1,
                                                                    fontFamily: "DM Sans Light",
                                                                    fontSize: '14px',
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    overflow: "hidden",
                                                                    WebkitLineClamp: 3,
                                                                    lineClamp: 3,
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {template.components?.find(component => component.type === "BODY")?.text}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    }
                                    {selectedTemplate !== null && (
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        mt: 1,
                                                        fontFamily: "DM Sans Light",
                                                        fontSize: '14px',
                                                        display: "-webkit-box",
                                                        WebkitBoxOrient: "vertical",
                                                        overflow: "hidden",
                                                        WebkitLineClamp: 3,
                                                        lineClamp: 3,
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {selectedTemplate.components?.find(component => component.type === "BODY")?.text}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {(() => {
                                                    const variables = collectVariables(selectedTemplate.components);
                                                    return variables.length > 0 && (
                                                        variables?.map((variable, index) => (
                                                           <Grid container direction="row" spacing={1} sx={{mt:4}} key={index}>
                                                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                                                    <Typography sx={{ fontFamily: "DM Sans", fontSize: "14px" }}>
                                                                        {formatKey(variable)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={12} sm={12} md={10} lg={10} xl={10}>
                                                                    <TextField 
                                                                        fullWidth
                                                                        size='small'
                                                                        value={variableValues[variable] ?? ""}
                                                                        onChange={(e) => { handleVariableValueChange(variable, e.target.value) }}
                                                                    />
                                                                </Grid>
                                                           </Grid>
                                                        ))
                                                    )
                                                })()}
                                            </Box>
                                            <Box sx={{mt:5}}>
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    sx={{
                                                        background: "#F5F5F5",
                                                        borderRadius: "5px",
                                                    }} 
                                                    disableRipple 
                                                    onClick={()=>{
                                                        setSelectedTemplate(null)
                                                    }}
                                                >
                                                    <ArrowBackRoundedIcon/>
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' variant='contained' onClick={handleSend} disabled={isButtonDisabled} disableFocusRipple disableRipple disableTouchRipple>Send</Button>
                </DialogActions>
            </Dialog>

            <Tooltip arrow placement="top" title="Template Message" enterDelay={1000}>
                <IconButton 
                    color="secondary"
                    sx={{
                        minHeight:'40px',
                        background: "#F5F5F5",
                        borderRadius: "5px",
                    }} 
                    disableRipple 
                    onClick={handleDialogOpen}
                >
                    {buttonIcon}
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}


TemplatedMessageInitializer.defaultProps = {
    buttonIcon: <BorderColorRoundedIcon/>,
    prefilData: {},
    resetToDefault: true,
};

export default TemplatedMessageInitializer